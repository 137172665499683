import { Card, Col, Row, Skeleton } from "antd";
import Container from "components/Container";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchJobDetail, fetchJobs } from "services/job";
import { setJobs } from "store/job";
import JobCard from "./JobCard";

export default function Jobs() {
	const jobs = useSelector((state) => state.job.jobs);
	const dispatch = useDispatch();
	const [view, setView] = useState("grid");

	const [activeJob, setActiveJob] = useState();

	useEffect(() => {
		(async () => {
			const _jobs = await fetchJobs();
			dispatch(setJobs(_jobs));
		})();
	}, []);

	const onListView = async (activeJobId) => {
		setView("list");

		if (activeJobId) {
			const _job = await fetchJobDetail(activeJobId);
			if (_job) {
				setActiveJob(_job);
			}
		}
	};

	return (
		<Container>
			<Row gutter={[20, 20]}>
				<Col span={view === "list" ? 8 : 24}>
					<Row gutter={[20, 20]}>
						{jobs
							? jobs?.map((job, idx) => (
									<Col key={idx} span={view === "grid" ? 8 : 24}>
										<JobCard {...job} onClick={onListView} />
									</Col>
							  ))
							: [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, idx) => (
									<Col key={idx} span={8}>
										<Card>
											<Skeleton />
										</Card>
									</Col>
							  ))}
					</Row>
				</Col>
				{view === "list" && activeJob ? (
					<Col span={16}>
						<div
							className=" overflow-y-auto pr-2.5"
							style={{ height: "calc(100vh - 140px)" }}
						>
							<div className="bg-white shadow border rounded-md overflow-hidden h-full">
								<img
									src="https://png.pngtree.com/thumb_back/fh260/background/20190828/pngtree-top-view-computer-design-background-image_307724.jpg"
									alt=""
									className="h-36 object-cover w-full"
								/>
								<div className="px-6">
									<img
										src={activeJob.thumbnail}
										alt=""
										className="h-16 w-16 inline-block object-cover border-4 border-solid border-white rounded-lg -mt-8"
									/>
									<div className="mt-3 flex items-center justify-between">
										<h1 className="text-2xl font-semibold">
											{activeJob.title}
										</h1>
										<div className="flex items-center">
											{/* <button className="text-xs font-medium px-2.5 !py-1 mr-2">
												Apply Now
											</button> */}
											{/* <div className="h-6 w-6 flex items-center justify-center border-2 border-gray-200 hover:border-primary-400 cursor-pointer rounded-md mr-1.5 hover:bg-primary-400 text-gray-600 hover:text-white">
												<HeartOutlined />
											</div>
											<div className="h-6 w-6 flex items-center justify-center border-2 border-gray-200 hover:border-primary-400 cursor-pointer rounded-md hover:bg-primary-400 text-gray-600 hover:text-white">
												<ShareAltOutlined />
											</div> */}
										</div>
									</div>
									<div className="flex justify-between items-center mt-1.5">
										<h3 className="text-sm font-semibold text-gray-500">
											{activeJob.location}
										</h3>
										<div className="flex items-center">
											<h3 className="text-sm text-gray-500 ">
												Posted {dayjs().diff(dayjs(activeJob.created_at), "d")}{" "}
												Days ago
											</h3>
											{/* <span className="mx-1.5 opacity-50 text-gray-500">
												&middot;
											</span>
											<h3 className="text-sm font-semibold text-gray-500 ">
												98 Applicants
											</h3> */}
										</div>
									</div>
									<div className="border-2 border-gray-200 rounded-lg mt-6 flex">
										<div className="p-2.5 border-r-2 border-gray-200 flex-1">
											<h5 className="text-xs mb-0.5 text-gray-500">
												Experience
											</h5>
											<h3 className="text-sm font-medium text-gray-500">
												{activeJob.experience}
											</h3>
										</div>
										<div className="p-2.5 border-r-2 border-gray-200 flex-1">
											<h5 className="text-xs mb-0.5 text-gray-500">
												Work Level
											</h5>
											<h3 className="text-sm font-medium text-gray-500">
												{_.capitalize(activeJob.level)}
											</h3>
										</div>
										<div className="p-2.5 border-r-2 border-gray-200 flex-1">
											<h5 className="text-xs mb-0.5 text-gray-500">
												Employment Type
											</h5>
											<h3 className="text-sm font-medium text-gray-500">
												{_.capitalize(activeJob.type)}
											</h3>
										</div>
										<div className="p-2.5 flex-1">
											<h5 className="text-xs mb-0.5 text-gray-500">
												Offer Salary
											</h5>
											<h3 className="text-sm font-medium text-gray-500">
												{_.capitalize(activeJob.salary)}
											</h3>
										</div>
									</div>
									{/* <div className="mt-6">
										<h1 className="text-base font-semibold">Overview</h1>
										<article className="text-sm mt-1">
											this is company info
										</article>
									</div> */}
									<div className="mt-5">
										<h1 className="text-base font-semibold">Description</h1>
										<article className="text-sm mt-1">
											{activeJob.description}
										</article>
									</div>
								</div>
							</div>
						</div>
					</Col>
				) : null}
			</Row>
		</Container>
	);
}
