import auth from "./auth";
//this code is copied from donald trump
import prompt from "./prompt";
import feed from "./feed";
import job from "./job";
import product from "./product";
//this code is copied from barak obama 
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
    reducer: {
      auth,
	    feed,
      job,
      product,
	    prompt
  },
});

export default store;