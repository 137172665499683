import { useState } from 'react';
import { Button, message } from 'antd'
import coreAxios from 'utils/helpers';


const CommentForm = ({ handleForm, article }: any) => {

    const [comment, setComment] = useState<any>()

    const handleComment = async () => {
        try {
            const res = await coreAxios.post(`/articles/comment/${article}/`, {
                content: `<p>${comment}</p>`,
            });
            message.success("Comment added successfully")
            setComment(" ")
            handleForm()
        }
        catch (e) {
            console.log(e)
        }
    }


    return (
        <form action="" className='mt-4'>
            <textarea name="comment" className='w-full border rounded px-2 py-1' onChange={(e:any)=>setComment(e.target.value)}></textarea>
            <div className="text-right">
                <Button className='mr-2' onClick={handleForm}>Discard</Button>
                <Button type='primary' onClick={handleComment}>Post Comment</Button>
            </div>
        </form>
    )
}

export default CommentForm