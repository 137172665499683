import _ from "lodash";
import React from "react";

export default function JobCard({
	id,
	title,
	description,
	onClick,
	thumbnail,
}) {
	return (
		<div
			className="shadow flex gap-1 cursor-pointer"
			onClick={() => {
				onClick(id);
			}}
		>
			<img
				src={thumbnail}
				alt="brand"
				className="w-24 h-24 rounded-sm object-cover"
			/>

			<div className="p-2">
				<h3 className="font-semibold text-base">{title}</h3>
				<p className="text-xs m-0">{_.truncate(description, { length: 80 })}</p>
			</div>
		</div>
	);
}
