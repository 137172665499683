import { Col, message, Row } from "antd";
import axios from "axios";
import Input from "components/Input";
import Modal from "components/Modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "services/job";
import { setJobs } from "store/job";
import { setJobPromptVisible } from "store/prompt";
import coreAxios from "utils/helpers";

export default function JobModal() {
	const visible = useSelector((state) => state.prompt.jobPrompt);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		title: "",
		description: "",
		salary: "",
		type: "full-time",
		experience: "",
		location: "",
		level: "entry",
	});

	const [avatar, setAvatar] = useState("");
	const [, setImageURL] = useState(null);

	const handleInput = (e) => {
		// console.log(e.target.name, e.target.value)
		setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
	};

	const closeModal = () => {
		dispatch(setJobPromptVisible(false));
	};

	const postJob = async () => {
		try {
			const imageData = new FormData();
			imageData.set("key", "4c0e7ebed82e54bf1ad6b9fa0109d8d8");
			imageData.append("image", avatar);

			const resp = await axios.post(
				"https://api.imgbb.com/1/upload",
				imageData
			);

			setImageURL(resp.data.data.display_url);

			const postable = {
				title: formData.title,
				description: formData.description,
				salary: formData.salary,
				type: formData.type,
				experience: formData.experience,
				location: formData.location,
				level: formData.level,
				thumbnail: resp.data.data.display_url,
			};

			await coreAxios.post("/jobs/", postable);

			message.success("Posted successfully");

			const _jobs = await fetchJobs();

			dispatch(setJobs(_jobs));

			closeModal();
		} catch (e) {
			// console.error(e);
		}
	};

	return (
		<Modal
			title="Post a job"
			visible={visible}
			okText="Post"
			onOk={postJob}
			onCancel={closeModal}
		>
			<div className="flex flex-col gap-5">
				<Input
					label={{ text: "Title" }}
					name="title"
					onChange={handleInput}
					value={formData.title}
				/>
				<Input
					label={{ text: "Job description" }}
					type="textarea"
					className="w-full min-h-[150px]"
					name="description"
					onChange={handleInput}
					value={formData.description}
				/>
				<Row gutter={[16]}>
					<Col span={12}>
						<Input
							label={{ text: "Salary" }}
							type="text"
							name="salary"
							value={formData.salary}
							onChange={handleInput}
						/>
					</Col>
					<Col span={12}>
						<Input
							onChange={handleInput}
							value={formData.type}
							label={{ text: "Job type" }}
							type="select"
							name="type"
						>
							<option value="full-time">Full time</option>
							<option value="part-time">Part time</option>
							<option value="internshiop">Internship</option>
							<option value="remote">Remote</option>
						</Input>
					</Col>
				</Row>

				<Row gutter={[16]}>
					<Col span={12}>
						<Input
							label={{ text: "Experience required in years" }}
							type="text"
							name="experience"
							value={formData.experience}
							onChange={handleInput}
						/>
					</Col>
					<Col span={12}>
						<Input
							onChange={handleInput}
							value={formData.level}
							label={{ text: "Candidate level" }}
							type="select"
							name="level"
						>
							<option value="entry">Entry level</option>
							<option value="junior">Junior-level</option>
							<option value="mid">Mid level</option>
							<option value="senior">Senior-level</option>
						</Input>
					</Col>
				</Row>

				<Row gutter={[16]}>
					<Col span={12}>
						<div>
							<label className="text-sm font-medium text-gray-900 block mb-2 ">
								Upload thumbnail
							</label>
							<input
								type="file"
								onChange={(e) => setAvatar(e.target.files[0])}
							/>
						</div>
					</Col>
					<Col span={12}>
						<Input
							label={{ text: "Job Location" }}
							type="text"
							name="location"
							value={formData.location}
							onChange={handleInput}
						/>
					</Col>
				</Row>
			</div>
		</Modal>
	);
}
