import { message } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setUser } from "store/auth";
import Axios from "../../../utils/helpers";
import storage from "../../../utils/storage";

export default function Signin() {
	const [formData, setFormData] = useState({
		password: "",
		email: "",
	});

	let navigate = useNavigate();
	const dispatch = useDispatch();
	const handleSignIn = async (e) => {
		e.preventDefault();
		//console.log({formData})
		try {
			const data = await Axios.post("/account/login/", {
				...formData,
				username: formData.email,
			});
			dispatch(setUser(data.data));
			storage.set("token", data.data.token);
			navigate("/", { replace: true });
		} catch (e) {
			message.error(e.response.data.non_field_errors[0]);
		}
	};

	const handleInput = (e) => {
		// console.log(e.target.name, e.target.value)
		setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
	};

	return (
		<div className="flex items-center justify-center w-full h-full">
			<div className="h-[calc(100vh-64px)] w-screen flex items-center justify-center">
				<div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-8 w-[384px]">
					<form className="space-y-6" onSubmit={handleSignIn}>
						<h3 className="text-xl text-center font-medium text-gray-900 ">
							Sign in to our platform
						</h3>
						<div>
							<label
								htmlFor="email"
								className="text-sm font-medium text-gray-900 block mb-2 "
							>
								Your email
							</label>
							<input
								type="email"
								name="email"
								id="email"
								className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
								placeholder="name@company.com"
								required={true}
								value={formData.email}
								onChange={handleInput}
							/>
						</div>
						<div>
							<label
								htmlFor="password"
								className="text-sm font-medium text-gray-900 block mb-2 "
							>
								Your password
							</label>
							<input
								type="password"
								name="password"
								id="password"
								placeholder="••••••••"
								className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
								required={true}
								value={formData.password}
								onChange={handleInput}
							/>
						</div>
						{/* <div className="flex items-start">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded "
                    required={true}
                  />
                </div>
                <div className="text-sm ml-3">
                  <label
                    htmlFor="remember"
                    className="font-medium text-gray-900 "
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <Link
                to="#"
                className="text-sm text-blue-700 hover:underline ml-auto"
              >
                Lost Password?
              </Link>
            </div> */}
						<button
							type="submit"
							className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
						>
							Login to your account
						</button>
						<div className="text-sm font-medium text-gray-500 ">
							Not registered?{" "}
							<Link to="/signup" className="text-blue-700 hover:underline">
								Create account
							</Link>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
