import { Card, message, Skeleton } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import coreAxios from "utils/helpers";
import { createMarkup } from "utils/misc";
import dayjs from "dayjs";
import { setFilteredFeed } from "store/feed";

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default function Article() {
    const articles = useSelector(state => state.feed.filtered_feed ? state.feed.filtered_feed : state.feed.feed);
    const tag = useSelector(state => state.feed.tag ? state.feed.tag : null);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            try {
                if (tag) {
                    const res = await coreAxios.get(`/articles/?tag=${tag}`);
                    dispatch(setFilteredFeed(res.data));
                }
                
                else {
                    const res = await coreAxios.get("/articles/");
                    dispatch(setFilteredFeed(res.data));
                }
            }
         catch (e) {
            console.log(e);
        }
    })();
}, [location, tag]);


return (
    <>
        {articles ? (
            articles.map(article => (
                <SingleArticle data={article} key={article.id} />
            ))
        ) : (
            <div className="flex flex-col gap-5">
                <Card>
                    <Skeleton />
                </Card>
                <Card>
                    <Skeleton />
                </Card>
                <Card>
                    <Skeleton />
                </Card>
            </div>
        )}
    </>
);
}


const SingleArticle = props => {
    const [article, setArticle] = useState(props.data)
    const likeHandler = (id) => {
        try {
            coreAxios.post(`http://localhost:8000/articles/like-post/${id}/`)
                .then(res => {
                    if (res.status === 201) {
                        message.success("You liked that post successfully")
                    } else {
                        message.warning("You unliked that post successfully")
                    }
                })

        }
        catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    message.error("Login first")
                }
            }

        }
    }
    return (
        <div className="flex w-full items-center justify-center mb-3">
            <div className="w-full rounded-xl border p-5 shadow-sm bg-white">
                <div className="flex w-full items-center justify-between border-b pb-3">
                    <div className="flex items-center space-x-3">
                        <div className="h-8 w-8 rounded-full bg-slate-400 bg-[url('https://i.pravatar.cc/32')]"></div>
                        <div className="text-lg font-bold text-slate-700">
                            {article.author.first_name + " " + article.author.last_name}
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <button className="rounded-2xl border bg-neutral-100 px-3 py-1 text-xs font-semibold">
                            Crypto
                        </button>
                        <div className="text-xs text-neutral-500">{
                            dayjs(`${article?.created_at?.slice(0, 10)}`).fromNow(true)
                        } ago</div>
                    </div>
                </div>

                <div className="mt-4 mb-6">
                    <Link to={`/article/${article.id}`} className="">
                        <div className="mb-3 text-xl font-bold">{article.title}</div>
                        <div className="text-sm text-neutral-600">
                            <div
                                dangerouslySetInnerHTML={createMarkup(
                                    _.truncate(article.content, { length: 300 })
                                )}
                            />
                        </div>
                    </Link>
                </div>

                <div>
                    <div className="flex items-center justify-between text-slate-500">
                        <div className="flex space-x-4 md:space-x-8">
                            <div className="flex cursor-pointer items-center transition hover:text-slate-600">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mr-1.5 h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                                    />
                                </svg>
                                <span>125</span>
                            </div>
                            <button className="flex cursor-pointer items-center transition hover:text-slate-600"
                                onClick={() => likeHandler(article?.id)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mr-1.5 h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                                    />
                                </svg>
                                <span>{article?.total_likes}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

