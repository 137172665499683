import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	user: localStorage.getItem("user")
		? JSON.parse(localStorage.getItem("user"))
		: undefined,
	authRequired: false,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
			localStorage.setItem("user", JSON.stringify(state.user));
		},
		setAuthRequired: (state, action) => {
			state.authRequired = action.payload;
		},
	},
});

export const { setUser, setAuthRequired } = authSlice.actions;
export default authSlice.reducer;
