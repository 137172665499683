import axios from "axios";

const coreAxios = axios.create({
	baseURL: process.env.REACT_APP_SERVICE_URL,
});

export default coreAxios;

coreAxios.interceptors.request.use(function (req) {
	let token = localStorage.getItem("token");

	if (token) {
		req.headers.authorization = "Token " + JSON.parse(token);
	}

	return req;
});

// coreAxios.interceptors.response.use(
// 	(response) => response,
// 	(error) => {
// 		if (error.response?.status === 401) {
// 			storage.clear();
// 			window.location.reload();
// 		}
// 		throw error;
// 	}
// );
