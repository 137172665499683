import { Card, message, Skeleton } from "antd";
import axios from "axios";
import Input from "components/Input";
import Modal from "components/Modal";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import coreAxios from "utils/helpers";
import { createMarkup } from "utils/misc";

export default function GetArticle() {
	let { id } = useParams();
	const [article, setArticle] = useState();
	
	const auth = useSelector(state => state.auth);

	const navigate = useNavigate();
	useEffect(() => {
		(async () => {
			try {
				const res = await coreAxios.get(`/articles/${id}/`);
				setArticle(res.data);
			} catch (e) {
				console.log(e);
			}
		})();
	}, [id]);

	const [title, setTitle] = useState(article?.title);
	const [content, setContent] = useState(article?.content);
	const [modalOpen, setModalOpen] = useState(false);
	const [articleModalOpen, setArticleModalOpen] = useState(false);

	const deletePost = (id) => {
		coreAxios.delete(`/articles/${id}/`);
		message.success("Post Deletd successfully");
		navigate("/", { replace: true });
	}

	const updatePost = (id) => {
		const res = coreAxios.put(`http://localhost:8000/articles/${id}/`,{
			"title":`${title}`,
			"content": `<p>${content}</p>`,
		});
		console.log(res)
		message.success("Post updated successfully");
		navigate("/", { replace: true });
	}
	
	return (
		<div>
			{/* Update Article  */}
			<Modal
				title="Write an article"
				visible={articleModalOpen}
				okText="Post"
				onOk={()=>updatePost(article.id)}
				onCancel={()=>setArticleModalOpen(false)}
			>
				<div className="flex flex-col gap-5">
					<Input
						label={{ text: "Title" }}
						name="title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
					<Input
						label={{ text: "Content" }}
						type="textarea"
						className="w-full min-h-[150px]"
						name="content"
						value={content}
						onChange={(e) => setContent(e.target.value)}
					/>
				</div>
			</Modal>

			{/* Delete Article  */}
			<Modal
				title="Vertically centered modal dialog"
				centered
				visible={modalOpen}
				onOk={() => {
					deletePost(article?.id)
					setModalOpen(false);
				}
				}
				onCancel={() => setModalOpen(false)}
			>
				<p>Are you sure want to delete this post?</p>
			</Modal>

			{article ? (
				<div>
					<div>
						<div className="flex items-center justify-between w-full mb-4">
							<div className="flex items-center">
								<div className="h-8 w-8">
									<img
										className="w-full h-full rounded-full object-cover"
										src="https://i.pravatar.cc/32"
										alt="avatar"
									/>
								</div>
								<div className="ml-2.5">
									<h1 className="text-md font-semibold m-0">
										{article?.author?.first_name + " " + article?.author?.last_name}
									</h1>
									<p className="font-light text-xs m-0">
										{dayjs(article?.created_at).format("MMM D, YYYY")}
									</p>
								</div>
							</div>
							{auth?.user?.username === article?.author?.username ? (
								<div className="flex gap-2">
									<button className="block bg-gray-900 rounded-full px-4 text-white font-normal py-1.5"
									onClick={() => setArticleModalOpen(true)}>
										Edit
									</button>
									<button className="block bg-gray-900 rounded-full px-4 text-white font-normal py-1.5"
										onClick={() => setModalOpen(true)}>
										Delete
									</button>
								</div>
							) : (
								<button className="block bg-gray-900 rounded-full px-4 text-white font-normal py-1.5">
									Follow
								</button>
							)}


						</div>
					</div>

					<h1 className="text-2xl font-bold"> {article?.title}</h1>
					<div dangerouslySetInnerHTML={createMarkup(article?.content)} />
					<hr className="mb-5"/>
					<p>Tag: <span className="bg-gray-100 rounded-2xl px-2 py-1">#{article?.tag}</span></p>
				</div>
			) : (
				<Card>
					<Skeleton />
				</Card>
			)}
		</div>
	);
}

