import "antd/dist/antd.css";
import AppLayout from "components/AppLayout";
import Protected from "components/Protected/Protected";
import GetArticle from "pages/Article";
import Explore from "pages/Explore";
import HomePage from "pages/Home";
import Jobs from "pages/Jobs";
import Products from "pages/Products";
import Profile from "pages/Profile";
import Signin from "pages/SignInSignUp/Signin";
import SignUp from "pages/SignInSignUp/SignUp";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Nav";
import Prompts from "./components/prompts";

function App() {
	const isLoggedIn = useSelector((state: any) => state.auth.user);
	return (
		<>
			<Navbar />
			<Routes>
				<Route element={<AppLayout />}>
					<Route
						path="/"
						element={
							<Protected isLoggedIn={isLoggedIn}>
								<HomePage />
							</Protected>
						}
					/>
					<Route
						path="profile"
						element={
							<Protected isLoggedIn={isLoggedIn}>
								<Profile />
							</Protected>
						}
					/>
					<Route
						path="search"
						element={
							<Protected isLoggedIn={isLoggedIn}>
								<HomePage />
							</Protected>
						}
					/>
					<Route path="article/:id" element={<GetArticle />} />
					<Route path="explore" element={<Explore />} />
					<Route path="products" element={<Products />} />
					<Route path="jobs" element={<Jobs />} />
					<Route path="profile" element={<Profile />} />
				</Route>
				<Route path="login" element={<Signin />} />
				<Route path="signup" element={<SignUp />} />
			</Routes>
			<Prompts />
		</>
	);
}

export default App;
