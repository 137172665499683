import coreAxios from "utils/helpers";

export const fetchTrendingArticles = async () => {
	try {
		const resp = await coreAxios.get("/articles/trending");
		return resp.data;
	} catch (err) {
		return;
	}
};

export const fetchSuggestedPeople = async () => {
	try {
		const resp = await coreAxios.get("/account/users");
		return resp.data;
	} catch (err) {
		return;
	}
};

export const followHandler = async (id) => {
	try {
		const res = await coreAxios.post(`account/follow/?id=${id}/`);
		return res.data;
	} catch (err) {
		return;
	}
};

export const getFollowings = async () => {
	const res = await coreAxios.get(`account/follow/`);
	return res;
};
