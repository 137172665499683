import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    jobs: null,
}

const jobSlice = createSlice({
    name: "job",
    initialState,
    reducers: {
        setJobs: (state, action) => {
            state.jobs = action.payload;
        },
    }
});
export const { setJobs } = jobSlice.actions;
export default jobSlice.reducer;