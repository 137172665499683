import HomePageBody from "./HomePageBody";

function HomePage() {
	return (
		<main>
			<HomePageBody />
		</main>
	);
}

export { HomePage };
