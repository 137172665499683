import React from "react";
import { Col, Row } from "antd";
import Article from "./Article";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import Container from "components/Container";

export default function HomePageBody() {
	return (
		<Container>
			<Row gutter={[40]}>
				<Col span={18} className="col-span-4">
					<Article />
				</Col>
				<Col span={6} className=" col-span-2">
					<RightSidebar />
				</Col>
			</Row>
		</Container>
	);
}
