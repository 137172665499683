import { Modal as AntModal } from "antd";
import React from "react";

export default function Modal({ children, ...props }:any) {
	return (
		<AntModal width={1000} {...props}>
			{children}
		</AntModal>
	);
}
