import { useState, useEffect } from "react";
import SuggestedPeople from "components/Suggestions/People";
import { useDispatch, useSelector } from "react-redux";
import coreAxios from "utils/helpers";
import { setMyFeed } from "store/feed";
import { Button, Card, Modal, Skeleton, Tabs, message } from "antd";
import SingleItem from "pages/Article/SingleArticle";
import { getFollowings } from "services/article";
import SingleFollowing from "./SingleFollowing";
import { BsThreeDots, BsPencilFill } from "react-icons/bs";
import ProfileForm from "./ProfileForm";
import BioModal from "./BioModal";
import Settings from "./Settings";

export default function Profile() {
	const [followings, setFollowings] = useState();
	const [profile, setProfile] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [photoModalOpen, setPhotoModalOpen] = useState(false);
	const [editPhoto, setEditPhoto] = useState(false);
	const [bioModalOpen, setBioModalOpen] = useState(false);
	const [data, setData] = useState();

	// Handle input field changes
	const handleChange = (event) => {
		setData((curr) => ({
			...curr,
			[event.target.name]: event.target.value,
		}));
	};

	// Show modal
	const showModal = (modalType) => {
		modalType === "bio" ? setBioModalOpen(true) : setIsModalOpen(true);
	};

	// Submit form
	const handleOk = () => {
		setIsModalOpen(false);
		setBioModalOpen(false);
		updateProfile();
		setPhotoModalOpen(false);
	};

	// CLose form
	const handleCancel = () => {
		setIsModalOpen(false);
		setBioModalOpen(false);
		setPhotoModalOpen(false);
	};

	// const user = useSelector((state) => state.auth.user);
	const articles = useSelector((state) => state.feed.my_feed);
	const dispatch = useDispatch();

	useEffect(() => {
		fetchFeed();
		fetchFollowings();
		fetchProfile();
	}, []);

	// Fetching feeds
	const fetchFeed = async () => {
		try {
			const res = await coreAxios.get(`/articles/my-articles`);
			dispatch(setMyFeed(res.data));
		} catch (e) {
			// console.log(e);
		}
	};

	// Fetching followings
	const fetchFollowings = async () => {
		try {
			const _followings = await getFollowings();
			setFollowings(_followings?.data);
		} catch (e) {
			// console.log(e);
		}
	};

	// Fetching profile data
	const fetchProfile = async () => {
		try {
			const res = await coreAxios.get(`/account/user`);
			setProfile(res.data);
		} catch (e) {
			// console.log(e);
		}
	};

	// Save profile changes
	const updateProfile = async () => {
		try {
			const res = await coreAxios.put(`/account/profile/${profile?.id}/`, data);
			message.success("Profile updated successfully");
			setProfile(res.data);
		} catch (e) {
			// console.log(e);
		}
	};

	const default_photo =
		"https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg";
	const photo_url = "http://localhost:8000" + `${profile?.avatar}`;

	return (
		<div className="container mt-5">
			<div className="grid grid-cols-12 gap-7">
				<div className="col-span-9">
					<Modal
						title="Update Avatar"
						visible={photoModalOpen}
						onOk={handleOk}
						onCancel={handleCancel}
					>
						<form action="">
							<input type="file" name="avatar" id="avatar" />
						</form>
					</Modal>
					<div className="shadow rounded overflow-hidden">
						<div className="relative">
							<img
								src="https://images.unsplash.com/photo-1639322537228-f710d846310a"
								alt="cover"
								className="w-full h-40 object-cover object-center"
							/>
							<div
								className="relative"
								onMouseOver={() => setEditPhoto(true)}
								onMouseLeave={() => setEditPhoto(false)}
							>
								<img
									src={`${profile?.avatar === "" ? default_photo : photo_url}`}
									alt="profile"
									className="w-32 h-32 rounded-full object-cover absolute -bottom-16 left-8"
								/>
								<button
									className={`${
										editPhoto ? "block" : "hidden"
									} absolute top-0 bg-white-500 text-gray-900 p-2 rounded-full border m-2 ml-5`}
									onClick={() => {
										setPhotoModalOpen(true);
									}}
								>
									<BsPencilFill />
								</button>
							</div>
						</div>
						<div className="mt-20 px-8 pb-5">
							<h3 className="font-semibold text-xl">
								{profile?.user?.first_name} {profile?.user?.last_name}
							</h3>
							<Modal
								title="Update Bio"
								visible={bioModalOpen}
								onOk={handleOk}
								onCancel={handleCancel}
							>
								<BioModal handleChange={handleChange} profile={profile} />
							</Modal>
							<div className="flex justify-between items-center">
								<p>{profile?.bio ? profile?.bio : "Waiting for update"}</p>
								<Button onClick={() => showModal("bio")}>
									<BsPencilFill />
								</Button>
							</div>
						</div>
					</div>

					<div className="mt-4">
						<Tabs defaultActiveKey="1">
							{/* Feeds  */}
							<Tabs.TabPane tab="Feed" key="1" className="">
								<div className="mt-4">
									{articles ? (
										articles.map((article) => (
											<SingleItem data={article} key={article.id} />
										))
									) : (
										<div className="flex flex-col gap-5">
											<Card>
												<Skeleton />
											</Card>
											<Card>
												<Skeleton />
											</Card>
											<Card>
												<Skeleton />
											</Card>
										</div>
									)}
								</div>
							</Tabs.TabPane>

							{/* About */}
							<Tabs.TabPane tab="About" key="2">
								<div className="bg-white p-3 shadow-sm rounded-xl border mt-4">
									<Modal
										title="Update Profile"
										visible={isModalOpen}
										onOk={handleOk}
										onCancel={handleCancel}
									>
										<ProfileForm
											handleChange={handleChange}
											profile={profile}
										/>
									</Modal>
									<div className="flex items-center justify-between px-5 py-3 space-x-2 font-semibold text-gray-900 leading-8">
										<span className="tracking-wide text-2xl">About</span>
										<Button onClick={showModal}>
											<BsThreeDots />
										</Button>
									</div>
									<hr />
									<div className="text-gray-700">
										<div className="grid md:grid-cols-2 text-sm">
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">
													First Name
												</div>
												<div className="px-4 py-2">
													{profile?.user?.first_name}
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">Last Name</div>
												<div className="px-4 py-2">
													{profile?.user?.last_name}
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">
													Profession Email
												</div>
												<div className="px-4 py-2">
													{" "}
													{profile?.professional_email
														? profile?.professional_email
														: "Waiting for update"}
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">
													Contact No.
												</div>
												<div className="px-4 py-2">
													{" "}
													{profile?.professional_email
														? profile?.professional_email
														: "Waiting for update"}
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">
													Current Address
												</div>
												<div className="px-4 py-2">
													{" "}
													{profile?.address
														? profile?.address
														: "Waiting for update"}
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">Facebook</div>
												<div className="px-4 py-2">
													{" "}
													{profile?.facebook
														? profile?.facebook
														: "Waiting for update"}
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">Email.</div>
												<div className="px-4 py-2">
													<a
														className="text-blue-800"
														href={`mailto:${profile?.user?.email}`}
													>
														{profile?.user?.email}
													</a>
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">Website</div>
												<div className="px-4 py-2">
													{" "}
													{profile?.website
														? profile?.website
														: "Waiting for update"}
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">Github</div>
												<div className="px-4 py-2">
													{" "}
													{profile?.github
														? profile?.github
														: "Waiting for update"}
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="px-4 py-2 font-semibold">Linkedin</div>
												<div className="px-4 py-2">
													{" "}
													{profile?.linkedin
														? profile?.linkedin
														: "Waiting for update"}
												</div>
											</div>
										</div>
									</div>
								</div>
							</Tabs.TabPane>

							{/* Followings */}
							<Tabs.TabPane tab="Followings" key="3">
								{followings ? (
									followings.map((item) => (
										<SingleFollowing data={item} key={item.id} />
									))
								) : (
									<div className="flex flex-col gap-5">
										<Card>
											<Skeleton />
										</Card>
										<Card>
											<Skeleton />
										</Card>
										<Card>
											<Skeleton />
										</Card>
									</div>
								)}
							</Tabs.TabPane>

							<Tabs.TabPane tab="Settings" key="4">
								<Settings />
							</Tabs.TabPane>
						</Tabs>
					</div>
				</div>

				<div className="col-span-3">
					<SuggestedPeople />
				</div>
			</div>
		</div>
	);
}
