import Container from "components/Container";
import Article from "./Article";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setTag } from "store/feed";

export default function Explore() {
	const dispatch=useDispatch()
	return (
		<Container>
			<div className="flex items-center gap-2 mb-6">
				{TAGS.map((tag) => (
					<button onClick={()=>dispatch(setTag(tag))} key={tag} className="border bg-gray-50 hover:bg-gray-100 rounded-full px-5 py-1.5 cursor-pointer">
						{tag}
					</button>
				))}
			</div>
			<Article/>
		</Container>
	);
}

const TAGS = [
	"Fintech",
	"Agritech",
	"Crypto",
	"Blockchain",
	"EdTech",
	"SAAS",
	"Community event",
	"AMA",
	"Product",
	"HealthTech",
];
