import { ChevronUpIcon } from "@heroicons/react/outline";
import { Empty, Skeleton } from "antd";
import Container from "components/Container";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "store/product";
import coreAxios from "utils/helpers";

export default function Products() {
	const products = useSelector((state) => state.product.products);
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			try {
				const res = await coreAxios.get(`/products/`);
				dispatch(setProducts(res.data));
			} catch (e) {
				// console.log(e);
			}
		})();
	}, []);

	return (
		<Container>
			<div>
				{products ? (
					products.length ? (
						products.map((item, idx) => (
							<div
								key={idx}
								className="flex items-center justify-between w-full p-4 rounded mb-4 shadow"
							>
								<div className="flex gap-4">
									<img
										className="w-20 h-20 object-cover rounded"
										src={item.avatar}
										alt="user"
									/>
									<div>
										<h1 className="font-semibold m-0">{item.name}</h1>
										<p className="font-light m-0">
											{_.truncate(item.description, { length: 270 })}
										</p>
									</div>
								</div>
								<button className="border rounded px-4 text-gray-800 hover:bg-gray-100 font-normal h-16 w-16 flex items-center justify-center">
									<span>
										<ChevronUpIcon className="w-6 h-6" />
										<span>65</span>
									</span>
								</button>
							</div>
						))
					) : (
						<Empty />
					)
				) : (
					<Skeleton />
				)}
			</div>
		</Container>
	);
}
