import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feed: null,
  my_feed: null,
  tag: null,
  filtered_feed:null
}

const feedSlice=createSlice({
    name:"feed",
    initialState,
    reducers:{
      setFeed:(state, action)=>{
        state.feed=action.payload;
      },
      setTag:(state, action)=>{
        state.tag=action.payload;
      },
      setFilteredFeed:(state, action)=>{
        state.filtered_feed=action.payload;
      },
      setMyFeed:(state, action)=>{
        state.my_feed=action.payload;
      },
    }
  })
  
  export const{setFeed, setTag, setFilteredFeed, setMyFeed}=feedSlice.actions;
  export default feedSlice.reducer;