import { Col, Row } from "antd";
import Sidebar from "pages/Home/Sidebar";
import React from "react";
import { Outlet } from "react-router-dom";
import Container from "./Container";

export default function AppLayout() {
	return (
		<Container>
			<Row gutter={[32]}>
				<Col span={4}>
					<Sidebar />
				</Col>
				<Col span={20}>
					<Outlet />
				</Col>
			</Row>
		</Container>
	);
}
