import React from 'react'

const BioModal = ({handleChange, profile}:any) => {
  return (
    <form action="">
        <textarea name="bio" id="bio" className='border w-full p-2' defaultValue={profile?.bio} onChange={handleChange}></textarea>
    </form>
  )
}

export default BioModal