import { message } from "antd";
import Input from "components/Input";
import Modal from "components/Modal";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import coreAxios from "utils/helpers";
import "assets/css/radio.css";
import { setArticlePromptVisible } from "store/prompt";
import { setFeed } from "store/feed";

export default function ArticleModal() {
	const visible = useSelector((state) => state.prompt.articlePrompt);
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [tag, setTag] = useState("");
	const dispatch = useDispatch();

	const closeModal = () => {
		dispatch(setArticlePromptVisible(false));
	};
	const postArticle = async () => {
		try {
			await coreAxios.post("/articles/", {
				title,
				content: `<p>${content}</p>`,
				tag,
			});

			resetStates();

			const res = await coreAxios.get("/articles/");
			dispatch(setFeed(res.data));
			message.success("Posted successfully");
			closeModal();
		} catch (e) {
			// console.error(e);
		}
	};

	const resetStates = () => {
		setTitle("");
		setContent("");
		setTag("");
	};

	return (
		<Modal
			title="Write an article"
			visible={visible}
			okText="Post"
			onOk={postArticle}
			onCancel={closeModal}
		>
			<p>&nbsp;</p>
			<div className="flex flex-col gap-5">
				<Input
					label={{ text: "Title" }}
					name="title"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<Input
					label={{ text: "Content" }}
					type="textarea"
					className="w-full min-h-[150px]"
					name="content"
					value={content}
					onChange={(e) => setContent(e.target.value)}
				/>
			</div>
			<div className="flex items-center mt-3">
				<h4 className="p-0 m-0 font-semibold ">Tags:</h4>
				<div className="radio-toolbar">
					<input
						type="radio"
						id="fintech"
						name="fintech"
						value="fintech"
						onChange={() => setTag("fintech")}
						checked={tag === "fintech" ? true : false}
					/>
					<label htmlFor="fintech">#Fintech</label>

					<input
						type="radio"
						id="agritech"
						name="agritech"
						value="agritech"
						onChange={() => setTag("agritech")}
						checked={tag === "agritech" ? true : false}
					/>
					<label htmlFor="agritech">#Agritech</label>

					<input
						type="radio"
						id="crypto"
						name="crypto"
						value="crypto"
						onChange={() => setTag("crypto")}
						checked={tag === "crypto" ? true : false}
					/>
					<label htmlFor="crypto">#Crypto</label>

					<input
						type="radio"
						id="blockchain"
						name="blockchain"
						value="blockchain"
						onChange={() => setTag("blockchain")}
						checked={tag === "blockchain" ? true : false}
					/>
					<label htmlFor="blockchain">#Blockchain</label>

					<input
						type="radio"
						id="edtech"
						name="edtech"
						value="edtech"
						onChange={() => setTag("edtech")}
						checked={tag === "edtech" ? true : false}
					/>
					<label htmlFor="edtech">#Edtech</label>

					<input
						type="radio"
						id="saas"
						name="saas"
						value="saas"
						onChange={() => setTag("saas")}
						checked={tag === "saas" ? true : false}
					/>
					<label htmlFor="saas">#Saas</label>

					<input
						type="radio"
						id="product"
						name="product"
						value="product"
						onChange={() => setTag("product")}
						checked={tag === "product" ? true : false}
					/>
					<label htmlFor="product">#Product</label>

					<input
						type="radio"
						id="healthtech"
						name="healthtech"
						value="healthtech"
						onChange={() => setTag("healthtech")}
						checked={tag === "healthtech" ? true : false}
					/>
					<label htmlFor="healthtech">#Healthtech</label>
				</div>
			</div>
		</Modal>
	);
}
