import { message, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { fetchSuggestedPeople, followHandler } from "services/article";

export default function SuggestedPeople() {
	const [suggestedPeople, setSuggestedPeople] = useState<any[]>();
	useEffect(() => {
		(async () => {
			const _peoples = await fetchSuggestedPeople();
			setSuggestedPeople(_peoples);
		})();
	}, []);

	return (
		<div>
			<h1 className="text-xl font-bold">You may follow</h1>
			<div>
				{suggestedPeople ? (
					suggestedPeople.map((user) => (
						<SingleFollow user={user} key={user.id} />
					))
				) : (
					<Skeleton />
				)}
			</div>
		</div>
	);
}

const SingleFollow = ({ user }: any) => {
	const [follow, setFollow] = useState<any>(false);
	const updateFollow = () => {
		setFollow(!follow);
		!follow
			? message.success("Following successfully")
			: message.error("Unfollowing successfully");
	};
	return (
		<div className="flex items-center justify-between w-full mb-4">
			<div className="flex items-center">
				<div className="h-8 w-8">
					<img
						className="w-full h-full rounded-full object-cover"
						src={
							user.image ??
							"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLyGpQvK8lACwKiCAwsikJrxkqzYtXfbLPNg&usqp=CAU"
						}
						alt="avatar"
					/>
				</div>
				<div className="ml-2.5">
					<h1 className="text-md font-semibold m-0">{user.first_name}</h1>
					<p className="font-light text-xs m-0">{user.bio}</p>
				</div>
			</div>
			<button
				onClick={() => {
					followHandler(user.id);
					updateFollow();
				}}
				className="block bg-gray-900 rounded-full px-4 text-white font-normal py-1.5"
			>
				{follow ? "Unfollow" : "Follow"}
			</button>
		</div>
	);
};
