import { message } from "antd";
import coreAxios from "utils/helpers";

export const fetchJobs = async () => {
	try {
		const resp = await coreAxios.get("jobs/");
		return resp.data;
	} catch (err) {
		message.error("Something wen't wrong");
	}
};

export const fetchJobDetail = async (id) => {
	try {
		const resp = await coreAxios.get(`jobs/${id}`);
		return resp.data;
	} catch (err) {
		message.error("Something wen't wrong");
	}
};
