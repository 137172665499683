import { TrendingUpIcon } from "@heroicons/react/solid";
import { Skeleton } from "antd";
import SuggestedPeople from "components/Suggestions/People";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchSuggestedPeople, fetchTrendingArticles } from "services/article";
import storage from "utils/storage";

export default function RightSidebar() {
	const [trendingArticles, setTrendingArticles] = useState();

	useEffect(() => {
		(async () => {
			const _articles = await fetchTrendingArticles();
			setTrendingArticles(_articles);
		})();
	}, []);
	return (
		<div>
			<div>
				<div className="flex items-center">
					<h1 className="text-xl font-bold">Trending</h1>
					<TrendingUpIcon className="w-6 h-6 ml-2 -translate-y-1" />
				</div>
				{trendingArticles ? (
					trendingArticles.map((article) => (
						<Link to={`/article/${article.id}/`} key={article.title}>
							<p className="text-md font-semibold">{article.title}</p>
						</Link>
					))
				) : (
					<Skeleton />
				)}
			</div>

			{storage.get("token") ? (
				<div className="mt-8">
					<SuggestedPeople />
				</div>
			) : null}
		</div>
	);
}
