import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	articlePrompt: false,
	jobPrompt: false,
	productPrompt: false,
};

const promptSlice = createSlice({
	name: "prompt",
	initialState,
	reducers: {
		setArticlePromptVisible: (state, action) => {
			state.articlePrompt = action.payload;
		},
		setProductPromptVisible: (state, action) => {
			state.productPrompt = action.payload;
		},
		setJobPromptVisible: (state, action) => {
			state.jobPrompt = action.payload;
		},
	},
});

export const {
	setArticlePromptVisible,
	setProductPromptVisible,
	setJobPromptVisible,
} = promptSlice.actions;
export default promptSlice.reducer;
