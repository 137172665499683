import { message } from 'antd'
import { useState } from 'react'
import { followHandler } from 'services/article'

const SingleFollowing = ({ data }: any) => {
  const [isFollowed, setIsFollowed] = useState(true)
  const date = new Date(data?.created_at)
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric"
  })
  const user = data?.following;
  const handleFollow = () => {
    setIsFollowed(!isFollowed)
    followHandler(user?.id)
    !isFollowed?message.success("Following successfully"):message.error("Unfollowing successfully");
  }
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center'>
        <div className="h-14 w-14 mr-3">
          <img
            className="w-full h-full rounded-full object-cover"
            src={
              user?.image ??
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLyGpQvK8lACwKiCAwsikJrxkqzYtXfbLPNg&usqp=CAU"
            }
            alt="avatar"
          />
        </div>
        <div>
          <h1 className="text-lg m-0">{user?.first_name} {user?.last_name}</h1>
          <p>Since {formattedDate}</p>
        </div>
      </div>
      <button
        onClick={handleFollow}
        className="block bg-gray-900 rounded-full px-4 text-white font-normal py-1.5 h-10">
        {isFollowed ? 'Unfollow' : 'Follow'}
      </button>
    </div>
  )
}

export default SingleFollowing