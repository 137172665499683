import React from "react";
import ArticleModal from "./ArticleModal";
import JobModal from "./JobModal";
import ProductModal from "./ProductModal";

export default function Prompts() {
	return (
		<div>
			<ArticleModal />
			<JobModal />
			<ProductModal />
		</div>
	);
}
