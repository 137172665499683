import { message } from "antd";
import axios from "axios";
import Input from "components/Input";
import Modal from "components/Modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "store/product";
import { setProductPromptVisible } from "store/prompt";
import coreAxios from "utils/helpers";

export default function ProductModal() {
	const visible = useSelector((state) => state.prompt.productPrompt);
	const dispatch = useDispatch();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [avatar, setAvatar] = useState("");
	const [, setImageURL] = useState(null);
	const [, setIsDisabled] = useState(false);

	const closeModal = () => {
		dispatch(setProductPromptVisible(false));
	};

	// const handleImageUpload = ({ fileList }) => {
	// 	setIsDisabled(true);
	// 	setAvatar(fileList[0]);
	// };

	const launchProduct = async () => {
		try {
			const imageData = new FormData();
			imageData.set("key", "4c0e7ebed82e54bf1ad6b9fa0109d8d8");
			imageData.append("image", avatar);
			axios
				.post("https://api.imgbb.com/1/upload", imageData)
				.then(function (response) {
					setImageURL(response.data.data.display_url);
					setIsDisabled(false);

					coreAxios
						.post("/products/", {
							name,
							description: description,
							avatar: response.data.data.display_url,
						})
						.then(() => {
							message.success("Posted successfully");
							coreAxios.get(`/products/`).then((res) => {
								dispatch(setProducts(res.data));
							});
						});
				})
				.catch(function (error) {
					// console.log(error);
				});

			closeModal();
		} catch (e) {
			// console.error(e);
		}
	};

	// const resetStates = () => {
	// 	setName("");
	// 	setDescription("");
	// 	setAvatar("");
	// 	setImageURL("");
	// };

	return (
		<Modal
			title="Launch a product"
			visible={visible}
			okText="Launch"
			onOk={launchProduct}
			destroyOnClose={true}
			onCancel={closeModal}
		>
			<div className="flex flex-col gap-5">
				<Input
					label={{ text: "Name" }}
					name="name"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<Input
					label={{ text: "Description" }}
					type="textarea"
					className="w-full min-h-[150px]"
					name="description"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
				/>

				<div>
					<label className="text-sm font-medium text-gray-900 block mb-2 ">
						Upload avatar
					</label>
					{/* <Upload
            beforeUpload={() => false}
            disabled={isDisabled}
            onChange={handleImageUpload}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload> */}
					<input type="file" onChange={(e) => setAvatar(e.target.files[0])} />
				</div>
			</div>
		</Modal>
	);
}
