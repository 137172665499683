import { Card, message, Skeleton } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import coreAxios from "utils/helpers";
import { createMarkup } from "utils/misc";
import dayjs from "dayjs";
import { setFeed } from "store/feed";
import SingleArticle from "pages/Article/SingleArticle";

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default function Article() {
	const articles = useSelector(state => state.feed.feed);
	const location = useLocation();
	const dispatch = useDispatch();
	
	useEffect(() => {
		(async () => {
			try {
				if (location.search) {
					const res = await coreAxios.get(`/articles/${location.search}`);
					dispatch(setFeed(res.data));
				} else {
					const res = await coreAxios.get("/articles/");
					dispatch(setFeed(res.data));
				}
			} catch (e) {
				console.log(e);
			}
		})();
	}, [location, dispatch]);


	return (
		<>
			{articles ? (
				articles.map(article => (
					<SingleArticle data={article} key={article.id}/>
				))
			) : (
				<div className="flex flex-col gap-5">
					<Card>
						<Skeleton />
					</Card>
					<Card>
						<Skeleton />
					</Card>
					<Card>
						<Skeleton />
					</Card>
				</div>
			)}
		</>
	);
}