import { Col, message, Row } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "../../../utils/helpers";

export default function SignUp() {
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		password: "",
		email: "",
	});

	let navigate = useNavigate();

	const handleSignUp = async (e) => {
		e.preventDefault();
		// console.log({formData})
		try {
			await Axios.post("/account/signup/", {
				...formData,
				username: formData.email,
			});

			navigate("/login", { replace: true });
		} catch (e) {
			message.error(e.response.data.non_field_errors[0]);
		}
	};

	const handleInput = (e) => {
		// console.log(e.target.name, e.target.value);
		setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
	};

	return (
		<div className="flex items-center justify-center w-full h-full">
			<div className="h-[calc(100vh-64px)] w-screen flex items-center justify-center">
				<div className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-8 w-[384px]">
					<form className="space-y-4" onSubmit={handleSignUp}>
						<h3 className="text-xl font-medium text-gray-900 pb-2 text-center">
							Sign up to our platform
						</h3>
						<Row gutter={[16]}>
							<Col span={12}>
								<div>
									<label
										htmlFor="firstName"
										className="text-sm font-medium text-gray-900 block mb-2 "
									>
										First name
									</label>
									<input
										type="text"
										name="first_name"
										id="firstName"
										className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
										placeholder="First name"
										required={true}
										value={formData.first_name}
										onChange={handleInput}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div>
									<label
										htmlFor="last_name"
										className="text-sm font-medium text-gray-900 block mb-2 "
									>
										Last name
									</label>
									<input
										type="text"
										name="last_name"
										id="lastName"
										className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
										placeholder="Last name"
										required={true}
										value={formData.last_name}
										onChange={handleInput}
									/>
								</div>
							</Col>
						</Row>
						<div>
							<label
								htmlFor="email"
								className="text-sm font-medium text-gray-900 block mb-2 "
							>
								Your email
							</label>
							<input
								type="email"
								name="email"
								id="email"
								className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
								placeholder="name@company.com"
								required={true}
								value={formData.email}
								onChange={handleInput}
							/>
						</div>
						<div>
							<label
								htmlFor="password"
								className="text-sm font-medium text-gray-900 block mb-2 "
							>
								Your password
							</label>
							<input
								type="password"
								name="password"
								id="password"
								placeholder="••••••••"
								className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
								required={true}
								value={formData.password}
								onChange={handleInput}
							/>
						</div>
						<div className="flex items-start">
							<div className="flex items-start">
								{/* <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded "
                    required={true}
                  />
                </div> */}
								{/* <div className="text-sm ml-3">
                  <label
                    htmlFor="remember"
                    className="font-medium text-gray-900 "
                  >
                    Remember me
                  </label>
                </div> */}
							</div>
							{/* <Link
                to="#"
                className="text-sm text-blue-700 hover:underline ml-auto"
              >
                Lost Password?
              </Link> */}
						</div>
						<button
							type="submit"
							className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
						>
							Sign up
						</button>
						<div className="text-sm font-medium text-gray-500 ">
							Already have an account?{" "}
							<Link to="/login" className="text-blue-700 hover:underline">
								Sign in
							</Link>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
