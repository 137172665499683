import { HandIcon } from '@heroicons/react/outline'
import { Button, message } from 'antd'
import { profile } from 'console'
import React, { useState } from 'react'
import coreAxios from 'utils/helpers'

const Settings = () => {

    const initialState={
        current_password:"",
        new_password:"",
        confirm_password:""
    }

    const [password, setPassword] = useState(initialState)
    const [passErr, setPassErr]=useState(false)

    // Handle input field changes
    const handleChange = (event:any) => {
        setPassword((curr:any) => ({
            ...curr,
            [event.target.name]: event.target.value,
        }));
    };

    const updatePassword=()=>{
        if(password.new_password===""){
            setPassErr(false)
            message.error("Add new password first")
        }
        if(password.current_password===""){
            message.error("Old password is required")
        }
        if(password.new_password!==password.confirm_password){
            setPassErr(true)
        }
        if(password.new_password===password.confirm_password){
            setPassErr(false)
            changePassword()
        }
    }

    const changePassword= async () => {
        try {
            const res = await coreAxios.put(`/account/change-password/`, password);
            if(res.status===400){
                message.error("Old password is wrong")
            }
            else{
                setPassword(initialState)
                message.success("Password updated successfully")
                console.log(initialState)
              
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    
    return (
        <div>
            <form action="">
                <label htmlFor="current_password">Current Password</label>
                <br />
                <input type="new_password" name="current_password" id="current_password" placeholder='Current Password' className='border p-2 w-full' onChange={handleChange}  defaultValue={initialState.current_password}/>
                <br /><br />
                <label htmlFor="new_password">New Password</label>
                <br />
                <input type="new_password" name="new_password" id="new_password" placeholder='New Password' className='border p-2 w-full' onChange={handleChange} defaultValue={initialState.new_password}/>
                <br /><br />
                <label htmlFor="confirm_password">Confirm Password</label>
                <br />
                <input type="new_password" name="confirm_password" id="confirm_password" placeholder='Confirm Password' className='border p-2 w-full' onChange={handleChange} defaultValue={initialState.confirm_password}/>
                <p className={`${passErr?'block':'hidden'} text-red-600 mb-0`}>Password unmatched</p>
                <br /><br />
                <Button type="primary" className="bg-blue-500" onClick={updatePassword}>Save Password</Button>
            </form>
        </div>
    )
}

export default Settings