import React from 'react'

const ProfileForm = ({handleChange, profile}:any) => {

  return (
    <div>
      <form action="" className='grid md:grid-cols-2  gap-4'>
        <input type="text" name="first_name" id="first_name" placeholder='First Name' className='border p-2' onChange={handleChange} defaultValue={profile?.user?.first_name}/>
        <input type="text" name="last_name" id="last_name" placeholder='Last Name' className='border p-2' onChange={handleChange} defaultValue={profile?.user?.last_name}/>
        <input type="text" name="address" id="address" placeholder='Current Address' className='border p-2' onChange={handleChange} defaultValue={profile?.address}/>
        <input type="text" name="phone_no" id="phone_no" placeholder='Phone Number' className='border p-2' onChange={handleChange} defaultValue={profile?.phone_no}/>
        <input type="email" name="email" id="email" placeholder='Email' className='border p-2' onChange={handleChange} defaultValue={profile?.email}/>
        <input type="email" name="professional_email" id="professional_email" placeholder='Professional Email' className='border p-2' onChange={handleChange} defaultValue={profile?.professional_email}/>
        <input type="text" name="facebook" id="facebook" placeholder='Facebook' className='border p-2' onChange={handleChange} defaultValue={profile?.facebook}/>
        <input type="text" name="github" id="github" placeholder='Github' className='border p-2' onChange={handleChange} defaultValue={profile?.github}/>
        <input type="text" name="linkedin" id="linkedin" placeholder='Linkedin' className='border p-2' onChange={handleChange} defaultValue={profile?.linkedin}/>
        <input type="text" name="website" id="website" placeholder='Website' className='border p-2' onChange={handleChange} defaultValue={profile?.website}/>
      </form>
    </div>
  )
}

export default ProfileForm